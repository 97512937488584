<template>
  <div
    v-if="version === 'v1'"
    class="property-list-item tw-flex tw-flex-col tw-border-2 tw-p-4 tw-text-start md:tw-min-h-[600px]"
    style="border-radius: var(--rounded)"
  >
    <nuxt-link
      :external="true"
      :to="property.detail_page_url"
      class="n-link tw-relative"
    >
      <library-image
        :src="property.image"
        :quality="60"
        style="border-radius: var(--rounded)"
      />
      <div
        v-if="property.custom_property_status_title"
        class="nc-property-image-badge"
      >
        {{ property.custom_property_status_title }}
      </div>
    </nuxt-link>
    <div class="tw-flex tw-flex-col tw-gap-4 tw-pt-4">
      <h4 class="tw-line-clamp-2 tw-h-[3.6rem]">
        {{ property.display_address }}
      </h4>
      <div class="tw-flex tw-items-center tw-justify-between">
        <div>
          <p v-if="property.price.type.name">{{ property.price.type.name }}</p>
          <h6>{{ property.price.price }}</h6>
        </div>
        <div
          v-if="property.match_percentage && !isMatchInformationHidden"
          class="tw-flex tw-items-center tw-gap-2"
        >
          <button
            class="n-primary !tw-cursor-default !tw-px-2 !tw-py-1 hover:!tw-bg-[var(--c-secondary)] hover:!tw-text-white"
          >
            <p>{{ property.match_percentage }} MATCH</p>
          </button>
        </div>
      </div>
      <div class="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-pt-4">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 11.25C9.38193 11.25 8.77775 11.0667 8.26384 10.7233C7.74994 10.38 7.3494 9.89191 7.11288 9.32089C6.87635 8.74987 6.81447 8.12154 6.93505 7.51534C7.05563 6.90915 7.35325 6.35233 7.79029 5.91529C8.22733 5.47825 8.78415 5.18063 9.39034 5.06005C9.99654 4.93947 10.6249 5.00135 11.1959 5.23788C11.7669 5.4744 12.255 5.87494 12.5983 6.38884C12.9417 6.90275 13.125 7.50693 13.125 8.125C13.124 8.9535 12.7945 9.74778 12.2086 10.3336C11.6228 10.9195 10.8285 11.249 10 11.25ZM10 6.25C9.62916 6.25 9.26665 6.35997 8.95831 6.566C8.64997 6.77202 8.40964 7.06486 8.26773 7.40747C8.12581 7.75008 8.08868 8.12708 8.16103 8.4908C8.23338 8.85451 8.41195 9.1886 8.67418 9.45083C8.9364 9.71305 9.27049 9.89163 9.63421 9.96397C9.99792 10.0363 10.3749 9.99919 10.7175 9.85728C11.0601 9.71536 11.353 9.47504 11.559 9.1667C11.765 8.85835 11.875 8.49584 11.875 8.125C11.8745 7.62787 11.6768 7.15125 11.3253 6.79972C10.9738 6.4482 10.4971 6.2505 10 6.25Z"
            fill="black"
          />
          <path
            d="M10 18.75L4.72751 12.5319C4.65425 12.4385 4.58174 12.3445 4.51001 12.25C3.60937 11.0636 3.12282 9.61452 3.12501 8.125C3.12501 6.30164 3.84934 4.55295 5.13865 3.26364C6.42796 1.97433 8.17664 1.25 10 1.25C11.8234 1.25 13.5721 1.97433 14.8614 3.26364C16.1507 4.55295 16.875 6.30164 16.875 8.125C16.8772 9.61384 16.3909 11.0623 15.4906 12.2481L15.49 12.25C15.49 12.25 15.3025 12.4963 15.2744 12.5294L10 18.75ZM5.50813 11.4969C5.50813 11.4969 5.65376 11.6894 5.68688 11.7306L10 16.8175L14.3188 11.7237C14.3463 11.6894 14.4925 11.4956 14.4931 11.495C15.2289 10.5257 15.6265 9.34189 15.625 8.125C15.625 6.63316 15.0324 5.20242 13.9775 4.14752C12.9226 3.09263 11.4918 2.5 10 2.5C8.50816 2.5 7.07742 3.09263 6.02253 4.14752C4.96764 5.20242 4.37501 6.63316 4.37501 8.125C4.3737 9.34265 4.77174 10.5271 5.50813 11.4969Z"
            fill="black"
          />
        </svg>
        <p>{{ property.display_address }}</p>
      </div>
      <div class="tw-flex tw-flex-row tw-items-center tw-gap-4">
        <p>Beds: {{ property.bedroom }}</p>
        <p>Baths: {{ property.bathroom }}</p>
        <p>Receptions: {{ property.reception }}</p>
      </div>
    </div>
  </div>

  <div
    v-if="version === 'v2'"
    class="property-list-item tw-group tw-relative tw-flex tw-flex-col tw-p-4 tw-text-start md:tw-min-h-[560px]"
    style="border-radius: var(--rounded)"
  >
    <nuxt-link
      :external="true"
      :to="property.detail_page_url"
      class="n-link tw-relative"
    >
      <template
        v-if="preMarketPropertyFeature && property.is_locked === true"
        class="tw-overflow-hidden"
      >
        <div
          class="tw-overflow-hidden"
          style="border-radius: var(--rounded-sm)"
        >
          <div class="tw-absolute tw-inset-0 tw-z-30 tw-bg-white/30"></div>
          <library-image :src="property.image" class="n-blur" />
        </div>
        <button
          v-if="property.custom_property_status_title"
          class="nc-property-image-badge n-primary tw-z-40"
        >
          {{ property.custom_property_status_title }}
        </button>
        <div
          class="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center"
          style="border-radius: var(--rounded-sm)"
        >
          <h3 class="tw-text-white">Pre-Market Property</h3>
        </div>
      </template>

      <template v-if="property.is_locked === false">
        <library-image
          :src="property.image"
          :quality="60"
          style="border-radius: var(--rounded-sm)"
        />
        <button
          v-if="property.custom_property_status_title"
          class="nc-property-image-badge n-primary"
        >
          {{ property.custom_property_status_title }}
        </button>
      </template>
    </nuxt-link>
    <div
      v-if="property.featured_video"
      class="tw-absolute tw-right-8 tw-top-8 tw-z-40 tw-flex tw-cursor-pointer tw-items-center tw-gap-2 tw-rounded-lg tw-duration-300 hover:tw-scale-95 group-hover:tw-bg-white group-hover:tw-p-3"
      @click="$emit('open-video-popup', property.featured_video)"
    >
      <h6 class="tw-opacity-0 tw-duration-300 group-hover:tw-opacity-100">
        Play video
      </h6>
      <div
        class="tw-rounded-lg tw-bg-white tw-p-3 tw-duration-300 group-hover:tw-bg-transparent group-hover:tw-p-0"
      >
        <svg
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_84_94)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6 4.5C5.20435 4.5 4.44129 4.81607 3.87868 5.37868C3.31607 5.94129 3 6.70435 3 7.5V28.5C3 29.2956 3.31607 30.0587 3.87868 30.6213C4.44129 31.1839 5.20435 31.5 6 31.5H30C30.7956 31.5 31.5587 31.1839 32.1213 30.6213C32.6839 30.0587 33 29.2956 33 28.5V7.5C33 6.70435 32.6839 5.94129 32.1213 5.37868C31.5587 4.81607 30.7956 4.5 30 4.5H6ZM12.9375 12.945C12.9708 12.6582 13.0706 12.3832 13.2291 12.1419C13.3875 11.9005 13.6002 11.6996 13.8501 11.555C14.1 11.4105 14.3802 11.3263 14.6684 11.3093C14.9566 11.2923 15.2448 11.3429 15.51 11.457C16.266 11.781 17.862 12.51 19.884 13.677C21.305 14.4892 22.6774 15.3837 23.994 16.356C24.2255 16.5283 24.4135 16.7522 24.543 17.0101C24.6726 17.2679 24.74 17.5525 24.74 17.841C24.74 18.1295 24.6726 18.4141 24.543 18.6719C24.4135 18.9298 24.2255 19.1537 23.994 19.326C22.6773 20.2973 21.305 21.1908 19.884 22.002C18.4706 22.8276 17.0101 23.5699 15.51 24.225C15.2448 24.3395 14.9566 24.3903 14.6683 24.3734C14.38 24.3566 14.0996 24.2725 13.8496 24.1278C13.5996 23.9832 13.3869 23.7821 13.2285 23.5406C13.0702 23.2991 12.9705 23.0239 12.9375 22.737C12.7575 21.1113 12.6694 19.4767 12.6735 17.841C12.6735 15.5145 12.8415 13.7625 12.9375 12.945Z"
              fill="var(--c-secondary)"
            />
          </g>
          <defs>
            <clipPath id="clip0_84_94">
              <rect width="36" height="36" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
    <div class="tw-flex tw-flex-col tw-gap-4 tw-pt-4">
      <h4 class="tw-line-clamp-2 tw-h-[3.6rem]">
        {{ property.display_address }}
      </h4>

      <template v-if="property.is_locked === false">
        <div class="tw-flex tw-items-center tw-justify-between">
          <div>
            <h5>{{ property.price.price }}</h5>
          </div>
          <div
            v-if="property.match_percentage && !isMatchInformationHidden"
            class="tw-flex tw-items-center tw-gap-2"
          >
            <button
              class="n-primary !tw-cursor-default !tw-px-2 !tw-py-1 hover:!tw-bg-[var(--c-secondary)] hover:!tw-text-white"
            >
              <p>{{ property.match_percentage }} MATCH</p>
            </button>
          </div>
        </div>
        <div
          class="tw-flex tw-h-16 tw-flex-row tw-items-center tw-gap-2 tw-pt-4"
        >
          <span>
            <svg
              width="15"
              height="18"
              viewBox="0 0 15 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.945 17.18C11.1238 15.2625 15 11.345 15 7.75C15 5.76088 14.2098 3.85322 12.8033 2.4467C11.3968 1.04018 9.48912 0.25 7.5 0.25C5.51088 0.25 3.60322 1.04018 2.1967 2.4467C0.790176 3.85322 2.96403e-08 5.76088 0 7.75C0 11.345 3.875 15.2625 6.055 17.18C6.45264 17.5349 6.967 17.7311 7.5 17.7311C8.033 17.7311 8.54736 17.5349 8.945 17.18ZM5 7.75C5 7.08696 5.26339 6.45107 5.73223 5.98223C6.20107 5.51339 6.83696 5.25 7.5 5.25C8.16304 5.25 8.79893 5.51339 9.26777 5.98223C9.73661 6.45107 10 7.08696 10 7.75C10 8.41304 9.73661 9.04893 9.26777 9.51777C8.79893 9.98661 8.16304 10.25 7.5 10.25C6.83696 10.25 6.20107 9.98661 5.73223 9.51777C5.26339 9.04893 5 8.41304 5 7.75Z"
                fill="black"
              />
            </svg>
          </span>

          <p>{{ property.display_address }}</p>
        </div>
        <div class="tw-flex tw-flex-row tw-items-center tw-gap-4">
          <p>
            Beds: <span class="tw-font-bold">{{ property.bedroom }}</span>
          </p>
          <p>
            Baths: <span class="tw-font-bold">{{ property.bathroom }}</span>
          </p>
          <p>
            Receptions:
            <span class="tw-font-bold">{{ property.reception }}</span>
          </p>
        </div>
      </template>
      <template
        v-else-if="preMarketPropertyFeature && property.is_locked === true"
      >
        <p>
          This is a pre-market property. You need to create an account and
          register to our property alerts in order to view it.
        </p>
        <nuxt-link to="/register" class="n-primary tw-w-fit" :external="true"
          >Register</nuxt-link
        >
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import type { PropertyItemComponent } from '~/units/properties/types';
import NcTooltip from '~/components/common/NcTooltip.vue';

export default defineNuxtComponent({
  name: 'PropertyListItem',

  components: { NcTooltip },

  props: {
    property: {
      required: true,
      type: Object as PropType<PropertyItemComponent>,
    },

    isMatchInformationHidden: {
      required: false,
      type: Boolean,
      default: false,
    },

    version: {
      required: false,
      type: String,
      default: 'v1',
    },

    preMarketPropertyFeature: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
});
</script>
